<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>

import Header from './componets/Header'
import Footer from './componets/Footer'
export default {
  name: 'App',
  components:{Header,Footer}
}
</script>

<style>
@import './assets/css/base.css'
</style>
