<template>
  <div class="head-box">
    <div class="header">
      <div class="container">
        <router-link to="/" class="logo"
          ><img src="../../assets/images/logo.png" alt=""
        /></router-link>
        <div class="nav">
          <ul>
            <li @mouseleave="hoverIndex = -1" @mouseenter="hoverIndex = 0">
              <router-link to="/solution" class="link" active-class="active"
                >Solutions</router-link>
              <div :class=" hoverIndex === 0 ? 'erify erify-show':'erify'">
                <router-link
                  v-for="item in soluteNav"
                    :key="item.id" 
                    :to="`/solution?id=${item.id}`">
                    {{ item.title }}
                </router-link>
              </div>
            </li>
            <li @mouseleave="hoverIndex = -1" @mouseenter="hoverIndex = 1">
              <router-link to="/product" class="link" active-class="active"
                >Products</router-link>
              <div :class=" hoverIndex === 1 ? 'erify erify-show':'erify'">
                <router-link
                  v-for="cateItem in proNav"
                  :key="cateItem.id" 
                  :to="`/product?id=${cateItem.id}`">
                  {{ cateItem.name }}
                </router-link>
              </div>
            </li>
            <li @mouseleave="hoverIndex = -1" @mouseenter="hoverIndex = 2">
              <router-link to="/innovation" class="link" active-class="active"
                >Innovation</router-link
              >
              <div :class=" hoverIndex === 2 ? 'erify erify-show':'erify'">
                <a href="javascript:void(0)" @click="gotoPos(0)">Technology</a>
                <a href="javascript:void(0)" @click="gotoPos(1)">Quality</a>
                <a href="javascript:void(0)" @click="gotoPos(2)">Manufacture</a>
              </div>
            </li>
            <li @mouseleave="hoverIndex = -1" @mouseenter="hoverIndex = 3">
              <router-link to="/sustainability" class="link" active-class="active"
                >ESG</router-link
              >
              <div :class=" hoverIndex === 3 ? 'erify erify-show':'erify'">
                <a href="javascript:void(0);" @click="gotoPos(0)">Strategy and Goals</a>
                <a href="javascript:void(0);" @click="gotoPos(1)">ESG Achievements</a>
                <a href="javascript:void(0);" @click="gotoPos(2)">DMEGC for Green</a>
                <a href="javascript:void(0);" @click="gotoPos(3)">Sustainable Procurement</a>
                <a href="javascript:void(0);" @click="gotoPos(4)">ESG News</a>
                <!-- <a href="javascript:void(0);" @click="gotoPos(5)">Downloads</a> -->
              </div>
            </li>
            <li>
              <router-link to="/download" class="link" active-class="active"
                >Download</router-link
              >
            </li>
            <li>
              <router-link to="/news" class="link" active-class="active">News</router-link>
            </li>
            <li @mouseleave="hoverIndex = -1" @mouseenter="hoverIndex = 6">
              <router-link to="/about" class="link" active-class="active"
                >About us</router-link
              >
              <div :class=" hoverIndex === 6 ? 'erify erify-show':'erify'">
                <a href="javascript:void(0)" @click="gotoPos(0)">Company</a>
                <a href="javascript:void(0)" @click="gotoPos(1)">Vision&Mission</a>
                <!-- <a href="javascript:void(0)" @click="gotoPos(2)">ESG</a> -->
                <a href="javascript:void(0)" @click="gotoPos(2)">Global presence</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="topR">
          <div class="language">
            <p class="fac" @click="showLanguage"><i></i>Global</p>
            <ul class="motion" :class="showLan?'lan-show':''" @mouseleave="hideLanguage">
              <li><a href="/">Global</a></li>
              <li><a href="/cn" target="_blank">中国</a></li>
              <li><a href="/de" target="_blank">Deutsch</a></li>
              <li><a href="/fr" target="_blank">Français</a></li>
              <li><a href="/es" target="_blank">Español</a></li>
              <li><a href="/it" target="_blank">Italiano</a></li>
              <li><a href="https://dmegcsolar.com.br/" target="_blank">Brasileiro</a></li>
            </ul>
          </div>
          <a href="https://www.hengdian.com/en/" target="_blank" class="website">Group Website</a>
          <div class="search"></div>
        </div>
      </div>
    </div>
    <div class="m-head">
      <div class="container">
        <router-link to="/" class="logo"
          ><img src="../../assets/images/logo.png" alt=""
        /></router-link>
        <div class="right">
          <div class="language" @click="showLanguage">Global</div>
          <img
          class="nav"
          @click="showHeader"
          src="../../assets/images/nav.png"
          alt=""
        />
        </div>
      </div>
    </div>
    <ul class="motion m-language" :class="showLan?'lan-show':''">
      <li><a href="/">Global</a></li>
      <li><a href="/cn">中国</a></li>
      <li><a href="/de">Deutsch</a></li>
      <li><a href="/fr">Français</a></li>
      <li><a href="/es">Español</a></li>
      <li><a href="/it">Italiano</a></li>
      <li><a href="https://dmegcsolar.com.br/">Brasileiro</a></li>
    </ul>
    <div class="m-main" v-show="showHead">
      <div class="container">
        <ul>
          <li>
            <router-link to="/solution" active-class="active"
              >Solutions</router-link
            >
          </li>
          <li>
            <router-link to="/product" active-class="active"
              >Products</router-link
            >
          </li>
          <li>
            <router-link to="/innovation" active-class="active"
              >Innovation</router-link
            >
          </li>
          <li>
            <router-link to="/sustainability" class="link" active-class="active"
                >ESG</router-link
              >
          </li>
          <li>
            <router-link to="/download" active-class="active"
              >Download</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">News</router-link>
          </li>
          <li>
            <router-link to="/about" active-class="active"
              >About us</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="header-h"></div>
  </div>
</template>

<script>
import { getSolutionList, getProCateList } from '../../request/api'
export default {
  name: "Header",
  data() {
    return {
      showHead: false,
      showLan: false,
      hoverIndex: -1,
      soluteNav:[],
      proNav:[],
      urlPath:''
    };
  },
  watch: {
    $route: function () {
      this.showHead = false
      this.hoverIndex = -1
      this.urlPath = this.$route.path
    },
  },
  mounted(){
    this.initHeader() 
  },
  methods: {
    async initHeader(){

      await getSolutionList().then( res => {

        this.soluteNav = res.data
      })

      await getProCateList().then(res => {

        this.proNav = res.data
      })
    },
    showHeader() {
      
      this.showHead = !this.showHead;
    },
    showLanguage() {

      this.showLan = !this.showLan
    },
    hideLanguage() {

      this.showLan = false
    },
    gotoPos(num){
    
      if(this.urlPath.indexOf('innovation') == -1 && this.hoverIndex ===2) {

        this.$router.push(`/innovation?pos=${num}`)
      }else if(this.urlPath.indexOf('about') == -1 && this.hoverIndex ===6) {

        this.$router.push(`/about?pos=${num}`)
      } else if(this.urlPath.indexOf('sustainability') == -1 && this.hoverIndex ===3) {

        this.$router.push(`/sustainability?pos=${num}`)
      } else {
        
        this.$bus.$emit('headNav',num)
        this.hoverIndex = -1
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  height: 80px;
  background: #fff;
  z-index: 99;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  }
  .logo {
    display: block;
    width: 133px;
    img {
      display: block;
      width: 100%;
    }
  }
  .nav {
    height: 80px;
  }
  ul {
    display: flex;
    li {
      margin-left: 2.6042vw;
      position: relative;
      &:first-child {
        margin-left: 0;
      }
    }
    .link {
      display: block;
      font-size: 17px;
      height: 80px;
      box-sizing: border-box;
      line-height: 78px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      position: relative;
    }
    .active {
      border-bottom: 2px solid #e60011;
    }
    .erify{
      position: absolute;
      left: 50%;
      top: 80px;
      display: none;
      opacity: 0;
      transition: all .5s;
      transform: translateY(40px);
      width: 180px;
      background: #fff;
      margin-left: -90px;
      a{
        display: block;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        line-height: 46px;
        &:hover{
          color: #e60011;
        }
      }
    }
    .erify-show{
      display: block;
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .topR {
    display: flex;
    align-items: center;
    height: 26px;
  }
  .language {
    position: relative;
    z-index: 10;
    p {
      width: 104px;
      height: 26px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 24px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 10px;
      cursor: pointer;
      background: url("../../assets/images/arrowB.png") no-repeat right 10px
        center;
      background-size: 8px 5px;
      i {
        display: block;
        width: 14px;
        height: 14px;
        background: url("../../assets/images/language.png") no-repeat;
        background-size: cover;
        margin-right: 5px;
      }
    }
    ul {
      position: absolute;
      left: 0;
      top: 25px;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      background: #fff;
      display: block;
      height: 0px;
      opacity: 0;
      padding-top: 10px;
      overflow: hidden;
      li{
          margin: 0;
      }
      a{
          display: block;
          font-size: 13px;
          line-height: 26px;
          height: 26px;
          font-weight: normal;
          text-align: center;
          color: rgba(0, 0, 0, 0.6);
      }
    }
    .lan-show{
        height: 202px;
        opacity: 1;
    }
  }
  .website {
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.3);
    padding-right: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    margin-left: 25px;
  }
  .search {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: url("../../assets/images/search.png") no-repeat;
    background-size: cover;
    margin-left: 20px;
  }
}
.m-head,
.m-language {
  display: none;
}
.header-h {
  height: 80px;
}

@media screen and (max-width: 750px) {
  .header {
    display: none;
  }
  .m-head {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 1.2rem;
    background: #fff;
    z-index: 99;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.2rem;
    }
    .logo {
      display: block;
      width: 2.16rem;
      img {
        display: block;
        width: 100%;
      }
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 2rem;
    }
    .language{
      font-size: .26rem;
      color: #333;
    }
    .nav {
      display: block;
      width: 0.56rem;
    }
  }
  .m-language{
    position: fixed;
    right: 0;
    top: 1.2rem;
    width: 2.6rem;
    background: #fff;
    z-index: 102;
    display: none;
    a{
      display: block;
      font-size: .26rem;
      line-height: .8rem;
      text-align: center;
      color: #333;
    }
  }
  .lan-show{
    display: block;
  }
  .m-main {
    position: fixed;
    left: 0;
    top: 1.2rem;
    width: 100%;
    height: calc(100vh - 1.2rem);
    background: #fff;
    z-index: 101;
    li {
      border-bottom: 1px solid #ccd2df;
      a {
        display: block;
        font-size: 0.3rem;
        line-height: 1rem;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .header-h {
    height: 1.2rem;
  }
}
</style>