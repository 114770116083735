import { post } from './https'


/*
    首页关于我们简介
    参数:无
*/
export const getHomeAboutInfo = p => post('/about/get_about_info', p)

/*
    banner 接口
    参数:{
        cate: home/download/innovate/news/about/share
    }
*/
export const getBannerList = p => post('/api/get_adv', p)

/*
    解决方案列表
    参数:{
        rmd:推荐：on||off
        rows:返回数量：默认全部
    }
*/
export const getSolutionList = p => post('/solution/get_solut_lists', p)

/*
    案例列表
    参数:{
        rmd：推荐：on||off
        rows: 返回数量：默认全部
    }
*/
export const getCaseList = p => post('/solution/get_case_list', p)

/*
    首页推荐产品分类
    参数:{

    }
*/
export const getHomeRmdproList = p => post('/product/get_pro_rmd_cate', p)

/*
    解决方案详情
    参数:{
        id:解决方案ID
    }
*/
export const getSolutionInfo = p => post('/solution/get_solut_info', p)



//首页产品推荐
export const getHomeProCateList = p => post('/product/get_pro_rmd_cate', p)

//获取产品一级分类
export const getProCateList = p => post('/product/get_pro_cate_tree',p)

//获取产品子类
export const getSubProCateList = p => post('/product/get_sub_cate_list', p)

//获取产品分类详情
export const getProCateInfo = p => post('/product/get_pro_cate_info', p)

//获取产品列表
export const getProList = p => post('/product/get_pro_lists', p)



/*
    科技创新
    参数:无
*/
export const getInnovationInfo = p => post('/innovate/index', p)


/*
    下载中心分类
    参数：无
*/
export const getLoadCateList = p => post('/download/get_cate_tree', p)

/*
    下载中心列表
    参数：{
        cid: 分类id,
        rows: 每页条数
        p: 页码
    }
*/
export const getLoadList = p => post('/download/get_down_lists', p)


/*
    新闻列表
    参数:{
        rows:每页条数
        p:页码
    }
*/
export const getNewsList = p => post('/news/get_lists', p)

/*
    新闻详情
    参数:{
        id: 新闻id
    }
*/
export const getNewsInfo = p => post('/news/get_detail', p)


/*
    企业介绍列表
    参数:无

*/
export const getComapanyList = p => post('/about/get_company_list', p)


/*
    企业愿景介绍
    参数:无
*/
export const getMissionInfo = p => post('/about/get_mission_info', p)


/*
    可持续发展列表
    参数:无
*/
export const getDevelopList = p => post('/about/get_develop_list', p)

/*
    底部业务咨询
    参数:{
        title: 标题
        email: 邮箱地址
        detail: 信息
    }
*/

export const updateBusinessInfo = p => post('/about/consult', p)



/*
    底部法律声明和隐私政策
    参数:{
        id:  10001 法律声明 10002 隐私政策
    }
*/

export const getArticleInfo = p => post('/api/get_article', p)


/*
    案例
    参数:{
        rows:10 一页个数
        p: 1 当前页码
    }
*/

/*
    持续发展
    参数：{
        cid
    }
*/
export const getSustainList = p => post('/sustain/get_lists', p)

/*
    持续发展
    参数：{
        id
    }
*/
export const getSustainInfo = p => post('/sustain/get_cate_info', p)


export const getSustainCateInfo = p => post('/sustain/get_cate_list',p);

export const getCaseDataList = p => post('/solution/get_page_case_list', p)

/*组件查询*/
export const queryCompoments = p => post('/product/components', p)

/*供应商查询*/
export const queryDealer = p => post('/product/dealer', p)
